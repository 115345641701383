var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    [
      _c(
        "a-col",
        { attrs: { span: 24 } },
        [
          _c("TableCustom", {
            attrs: {
              dataSource: _vm.dataSource,
              columns: _vm.columnsTable,
              scroll: { x: "calc(700px + 50%)", y: 400 },
              paginationcustom: false,
              defaultPagination: true
            },
            on: {
              "on-columnClicked": _vm.handleClickColumn,
              "on-columnClickedArray": _vm.handleClickColumnArray
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }