

















import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import { generalJournalServices } from "@/services/generaljournal.service";
import moment from "moment";
import Vue, { PropType } from "vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  AssetHistories,
  ResponseGetDetailInquiry,
} from "@/models/interface/assets.interface";
export default Vue.extend({
  props: {
    dataAssetHistory: {
      type: Object as PropType<ResponseGetDetailInquiry>,
    },
    currentTab: String,
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      totalElements: 1 as number,
      limit: 10 as number,
      dataSource: [] as AssetHistories[],
      columnsTable: [
        {
          title: "Reference Number",
          dataIndex: "referenceNo",
          key: "referenceNo",
          width: 250,
          scopedSlots: { customRender: "clickColumn" },
        },
        {
          title: "Book Type",
          dataIndex: "bookType",
          key: "bookType",
          width: 120,
        },
        {
          title: "Transaction Type",
          dataIndex: "transactionType",
          key: "transactionType",
          scopedSlots: { customRender: "clickColumn" },
          width: 140,
        },
        {
          title: "Journal Number",
          dataIndex: "journalNumbers",
          key: "journalNumbers",
          scopedSlots: { customRender: "clickColumnArrayMode" },
          width: 140,
        },
        {
          title: "Asset Number",
          dataIndex: "assetNo",
          key: "assetNo",
          width: 140,
        },
        {
          title: this.$root.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          scopedSlots: { customRender: "isRenderTag" },
          width: 500,
        },
        {
          title: "Transaction Date",
          dataIndex: "transactionDate",
          key: "transactionDate",
          width: 140,
        },
        {
          title: "Period",
          dataIndex: "period",
          key: "period",
          width: 120,
        },
        {
          title: "Transaction Details",
          dataIndex: "customtransactionDetail",
          key: "customtransactionDetail",
          scopedSlots: { customRender: "isRenderTag" },
          width: 250,
        },
        {
          title: this.$t("lbl_unit_code_parent"),
          dataIndex: "parentUnitCode",
          key: "parentUnitCode",
          width: 150,
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 120,
        },
        {
          title: "SN",
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 120,
        },
        {
          title: "By",
          dataIndex: "modifiedBy",
          key: "modifiedBy",
          width: 120,
        },
        {
          title: "Date Time",
          dataIndex: "customDateTime",
          key: "customDateTime",
          width: 160,
        },
      ],
    };
  },
  methods: {
    async handleClickColumnArray(_record, objColumnNameValue) {
      let data;
      const paramsJournal = {
        page: 0,
        limit: 10,
        search: `name~${objColumnNameValue.value}`,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (objColumnNameValue.column === "journalNumbers") {
        data = await generalJournalServices.listGeneralJournal(paramsJournal);
        if (data.data.length > 0) {
          this.$confirm({
            title: "You will be redirected to another page, are you sure?",
            onOk: () => {
              this.$router.push(
                `/generaljournal/journal/detail/${data.data[0].id}`
              );
            },
            onCancel() {
              return;
            },
          });
        }
      }
    },
    confirmAssetAdditions(data, routeParamsRefNo) {
      if (data.data.length > 0) {
        this.$confirm({
          title: "You will be redirected to another page, are you sure?",
          onOk: () => {
            this.$router.push(`/additions/single/view/${routeParamsRefNo}`);
          },
          onCancel() {
            return;
          },
        });
      }
    },
    confirmAdjustment(data, routeParamsRefNo) {
      this.$confirm({
        title: "You will be redirected to another page, are you sure?",
        onOk: () => {
          if (data.length > 0) {
            this.$router.push(`/inquiry/adjustment/view/${routeParamsRefNo}`);
          } else {
            this.$notification["error"]({
              message: "Error",
              description: "Data not found",
            });
          }
        },
        onCancel() {
          return;
        },
      });
    },
    confirmRetirement(data, routeParamsRefNo) {
      if (data.data.length > 0) {
        this.$confirm({
          title: "You will be redirected to another page, are you sure?",
          onOk: () => {
            this.$router.push(`/inquiry/retirement/view/${routeParamsRefNo}`);
          },
          onCancel() {
            return;
          },
        });
      }
    },
    async handleClickColumn(record, objColumnNameValue) {
      let params = {
        page: 0,
        limit: 10,
        search: `referenceNo~${record.referenceNo}`,
      } as RequestQueryParamsModel;
      let data;
      let routeParamsRefNo = encodeURIComponent(record.referenceNo);
      const paramsRetirement = {
        page: 0,
        limit: 10,
        search: `documentNumber~${record.referenceNo}`,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (objColumnNameValue.column === "transactionType") {
        switch (objColumnNameValue.value) {
          case "Addition":
            data = await assetsServices.listAssetAdditions(params);
            this.confirmAssetAdditions(data, routeParamsRefNo);
            break;
          case "Adjustment":
            data = await assetsServices.detailAssetAdjustment(params);
            this.confirmAdjustment(data, routeParamsRefNo);
            break;
          case "Retirement":
            data = await assetsServices.detailAssetRetirement(
              "",
              paramsRetirement
            );
            this.confirmRetirement(data, routeParamsRefNo);
            break;
          default:
            break;
        }
      } else if (objColumnNameValue.column === "referenceNo") {
        switch (record.transactionType) {
          case "Addition":
            data = await assetsServices.listAssetAdditions(params);
            this.confirmAssetAdditions(data, routeParamsRefNo);
            break;
          case "Adjustment":
            data = await assetsServices.detailAssetAdjustment(params);
            this.confirmAdjustment(data, routeParamsRefNo);
            break;
          case "Retirement":
            data = await assetsServices.detailAssetRetirement(
              "",
              paramsRetirement
            );
            this.confirmRetirement(data, routeParamsRefNo);
            break;
          default:
            break;
        }
      }
    },
  },
  watch: {
    dataAssetHistory: {
      immediate: true,
      deep: true,
      handler() {
        const { assetHistories } = this.dataAssetHistory;
        // this.dataSource = assetHistories
        this.dataSource = assetHistories.map((dataMap) => {
          let tempDataArray = [] as string[];
          dataMap.customDateTime = moment(dataMap.createdDate).format(
            "DD-MMM-yyyy HH:mm:ss"
          );
          dataMap.description = dataMap.description?.replaceAll("\n", "<br>");
          if (dataMap.transactionDetail) {
            let tempTransactionDetail =
              Object.keys(dataMap.transactionDetail.oldData).length > 0
                ? dataMap.transactionDetail.oldData
                : dataMap.transactionDetail.newData;
            for (const key in tempTransactionDetail) {
              let tempData = "" as string;
              if (key && tempTransactionDetail[key]) {
                if (dataMap.customtransactionDetail) {
                  tempData += key + " = " + tempTransactionDetail[key] + "<br>";
                } else {
                  tempData = key + " = " + tempTransactionDetail[key] + "<br>";
                }
              }
              tempDataArray.push(tempData);
            }
          } else {
            tempDataArray.push("");
          }
          dataMap.customtransactionDetail = tempDataArray.join("").toString();
          dataMap.transactionDate = moment(dataMap.transactionDate).format(
            "DD MMM yyyy HH:mm:ss"
          );
          return dataMap;
        });
      },
    },
  },
});
